<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">DISPATCH ASSETS</span>
                <div class="details-view-subtitle">List of dispatch asset count per asset type</div>
            </b-col>
            <b-col sm="6" md="5" offset-sm="6" offset-md="1" class="text-md-right mb-2">
                <b-input-group align="right" class="pagination" prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>

            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>

            <template v-slot:cell(reasonForUpdate)="row">
                <span class="truncate-text">
                    <truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
                        type="html" :text="row.item.notes" />
                </span>
            </template>

            <template v-slot:cell(expected)="row">
                <span class="numFont">
                    {{ row.item.expected.toLocaleString() }}
                </span>
            </template>

            <template v-slot:cell(actual)="row">
                <span class="numFont">
                    {{ row.item.actual.toLocaleString() }}
                </span>
            </template>

            <template v-slot:cell(actions)="row">
                <span v-if="dispatchStatus === 'Received'">
                    <b-button v-if="isClassified(row.item)" size="sm" v-b-tooltip.hover.top="'View Classification'"
                        variant="dark" @click.stop="updateSelectedRow(row.item, 'view')" class="mr-1">
                        <em class="fa fa-eye"></em>
                    </b-button>
                    <b-button v-else size="sm" v-b-tooltip.hover.top="'Start Classification'" variant="primary"
                        @click.stop="updateSelectedRow(row.item, 'start')" class="mr-1">
                        <img class="folder-tree" src="img/folder-tree.svg" alt="Classification" />
                    </b-button>

                    <span v-if="isNotCompleted(row.item)">
                        <b-button size="sm" v-b-tooltip.hover.top="'Edit Classification'" variant="warning"
                            @click.stop="updateSelectedRow(row.item, 'edit')" class="mr-1">
                            <i class="fa fa-pencil"></i>
                        </b-button>
                        <b-button size="sm" v-b-tooltip.hover.top="'Complete Classification'" variant="primary"
                            @click.stop="updateSelectedRow(row.item, 'complete')" class="mr-1">
                            <em class="fa fa-check"></em>
                        </b-button>
                    </span>
                </span>
                <span v-else>
                    <i>No Action Needed</i>
                </span>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
    name: 'dispatch-details-asset-view',
    props: {
        row: {
            type: Object,
            required: true,
        },
        allClassifications: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'index',
                    label: '#',
                },
                {
                    key: 'name',
                    label: 'Asset Type',
                },
                {
                    key: 'expected',
                    label: 'Expected',
                    class: 'text-right'
                },
                {
                    key: 'actual',
                    label: 'Actual',
                    class: 'text-right'
                },
                {
                    key: 'actions',
                    class: 'text-center'
                }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    computed: {
        dispatchStatus() {
            return this.row.item.status;
        }
    },
    mounted() {
        this.updateTable();

        EventBus.$on('onLoadAssetsClassification', () => {
            this.updateTable();
        });
    },
    methods: {
        updateTable() {
            let item = this.row.item;
            let assetsObj = {};

            if (!_.isEmpty(item.assets)) {
                _.forEach(item.assets, (asset) => {
                    let assetEntry = {
                        id: asset.assetTypeId,
                        name: asset.assetType,
                        expected: asset.expectedQuantity,
                        actual: asset.actualQuantity
                    };

                    assetsObj[asset.assetTypeId] = assetEntry;
                });
            }

            this.items = Object.values(assetsObj);
            this.totalRows = this.items.length;
        },
        updateSelectedRow(dispatchAsset, action) {
            const dispatch = this.row.item;
            let classification = { ...this.allClassifications[dispatchAsset.name] };

            switch (action) {
                case 'start':
                case 'edit':
                    {
                        const params = {
                            dispatch: dispatch,
                            assetType: dispatchAsset,
                            classification: classification
                        }
                        EventBus.$emit('onClassifyDispatchAssets', params);
                        this.$bvModal.show('classify-dispatch-assets');
                    }
                    break;
                case 'view':
                case 'complete':
                    {
                        const params = {
                            classification: classification,
                            completed: action === 'complete',
                        }
                        EventBus.$emit('onViewClassificationDetails', params);
                        this.$bvModal.show('mark-classification-as-done');
                    }
                    break;
            }
        },
        isClassified(dispatchAsset) {
            return this.allClassifications[dispatchAsset.name];
        },
        isNotCompleted(dispatchAsset) {
            let classification = this.allClassifications[dispatchAsset.name];
            return classification && !classification.isCompleted;
        }
    },
    beforeDestroy() {
        EventBus.$off('onCloseSaveClassification');
    }
}
</script>