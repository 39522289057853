<template>
	<div>
		<b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
			@click.stop="row.toggleDetails" class="mr-1 mt-1">
			<i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
			<i class="fa fa-eye" v-else></i>
		</b-button>

		<b-button v-show="status === 'Draft' && !isViewer" size="sm" v-b-tooltip.hover.top="'Edit Details'"
			variant="warning" @click.stop="editDispatch(row.item, row.index, $event.target)" class="mr-1 mt-1">
			<i class="fa fa-pencil"></i>
		</b-button>

		<span v-b-tooltip.hover="disabledDeployTooltip">
			<b-button v-show="isAllowedToDeploy(row.item)" :disabled="driverUserId.length > 0" size="sm"
				v-b-modal.deploy-dispatch v-b-tooltip.hover.top="'Deploy Dispatch'" variant="success"
				@click.stop="deployDispatch(row.item)" class="mr-1 mt-1">
				<i class="fa fa-share"></i>
			</b-button>
		</span>

		<b-button v-show="isAllowedToReceiveOrReject(row.item)" size="sm" v-b-modal.receive-dispatch
			v-b-tooltip.hover.top="'Receive Dispatch'" variant="success" @click.stop="receiveDispatch(row.item)"
			class="mr-1 mt-1">
			<i class="fa fa-check"></i>
		</b-button>

		<b-button v-show="isAllowedToReceiveOrReject(row.item)" size="sm" v-b-modal.reject-dispatch
			v-b-tooltip.hover.top="'Reject Dispatch'" variant="danger" @click.stop="rejectDispatch(row.item)"
			class="mr-1 mt-1">
			<i class="fa fa-close"></i>
		</b-button>
		
		<b-button v-show="isAllowedToCancel()" size="sm" v-b-modal.cancel-dispatch
			v-b-tooltip.hover.top="'Cancel Dispatch'" variant="danger"
			@click.stop="cancelDispatch(row.item)" class="mr-1 mt-1">
			<em class="fa fa-ban"></em>
		</b-button>

		<b-button size="sm" v-b-modal.print-dispatch-summary v-b-tooltip.hover.top="'Print Dispatch Summary'"
			variant="primary" @click.stop="printDispatch(row.item)" class="mr-1 mt-1">
			<i class="fa fa-print"></i>
		</b-button>
	</div>
</template>

<script>
// Util
import { UserUtil } from '@/utils/userutil';

// Others
import EventBus from '@/shared/event-bus';

export default {
	name: 'dispatch-row-actions',
	props: {
		row: {
			type: Object,
			required: true,
		},
		parentComponent: {
			type: String,
			require: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			disabledDeployTooltip: '',
			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isManager: this.$store.getters.isManager,
			isSupervisor: this.$store.getters.isSupervisor,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
		};
	},
	computed: {
		status() {
			return this.row && this.row.item.status;
		},
		driverUserId() {
			let driver = this.row && this.row.item.driver ? this.row.item.driver : {};
			return driver.userId && driver.userId.length > 0 ? driver.userId : '';
		},
		destinationCompanyId() {
			return this.row && this.row.item.destination.companyId;
		},
		sourceCompanyId() {
			return this.row && this.row.item.source.companyId;
		},
		currCompanyId() {
			return this.loggedUserCompany.id;
		}
	},
	mounted() {
		if (this.driverUserId.length > 0) {
			this.disabledDeployTooltip = 'Dispatch has an active driver';
		} else {
			this.disabledDeployTooltip = '';
		}
	},
	methods: {
		editDispatch(item) {
			// init parameters
			this.params = this.$store.getters.dispatchParams;
			this.params.selDispatch = item;

			this.$store.dispatch('setDispatchParams', this.params);
			this.$store.dispatch('setCurrentDispatch', item);

			if (this.isSuperAdmin) {
				this.$router.push({ path: '/admin/admin-edit-dispatch' });
			} else if (!this.isSuperAdmin) {
				this.$router.push({ path: '/edit-dispatch' });
			} else {
				this.$toaster.warning('Invalid Source Screen');
			}
		},
		deployDispatch(item) {
			this.$store.commit('SET_CURR_DISPATCH', item);
			EventBus.$emit('onDeployDispatch', item);
		},
		receiveDispatch(item) {
			this.$store.commit('SET_CURR_DISPATCH', item);
			EventBus.$emit('onReceiveDispatch', item);
		},
		rejectDispatch(item) {
			this.$store.commit('SET_CURR_DISPATCH', item);
			EventBus.$emit('onRejectDispatch', item);
		},
		cancelDispatch(item) {
			this.$store.commit('SET_CURR_DISPATCH', item);
			EventBus.$emit('onCancelDispatch', item);
		},
		printDispatch(item) {
			this.$store.commit('SET_CURR_DISPATCH', item);
			EventBus.$emit('onPrintDispatch', item);
		},

		isAllowedToDeploy() {
			return this.status === 'Draft' && !this.isViewer;
		},
		isAllowedToCancel() {
			if (this.parentComponent === 'Dispatch') {
				if (this.status === 'Receiving') {
					// Admin, Manager, and Dispatch created by the logged user
					return this.isSuperAdmin || this.isManager || this.loggedUser.id === this.row.item.createdBy;
				} else {
					// Admin, Manager, and Supervisor
					return (this.status === 'Draft' || this.status === 'In-Transit')
						&& (this.isSuperAdmin || this.isManager || this.isSupervisor);
				}
			} else if (this.parentComponent === 'Receipt') {
				// Admin, Manager, and Dispatch created by the logged user
				return this.status === 'Receiving'
					&& (this.isSuperAdmin || this.isManager || this.loggedUser.id === this.row.item.createdBy);
			}
			return false;
		},
		isAllowedToReceiveOrReject(dispatch) {
			return (this.status === 'In-Transit' || this.status === 'Receiving')
				&& (this.destinationCompanyId === this.currCompanyId
					|| (this.sourceCompanyId === this.currCompanyId && dispatch.toInactiveNode === 'true')
					|| (this.hasCompanyAccess(this.sourceCompanyId, this.destinationCompanyId)))
				&& !this.isViewer && this.parentComponent === 'Receipt';
		},
		hasCompanyAccess(sourceCompanyId, destinationCompanyId) {
			return UserUtil.hasCompanyAccess(this.allCompaniesObj, sourceCompanyId) || UserUtil.hasCompanyAccess(this.allCompaniesObj, destinationCompanyId);
		}
	},
};
</script>